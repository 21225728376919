import React from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import FreedomBanner from '../components/freedomBanner/freedomBanner';
// import LayoutMap from '../components/wordPress/layouts/layoutMap/layoutMap';
import InsuranceProviders from '../components/insuranceProviders/insuranceProviders';

const ContactUsPage = () => {
  return (
    <Layout>
       <Seo
        canonical={`https://www.essentialbehavioral.com/contact-us/`}
      />

      <>
        <header className={`pt-6 text-header`}>
          <Container>
            <Row className={`justify-content-center text-center`}>
              <Col xs={11} md={8}>
                <h1>Contact Us</h1>
                <pre>Take the first step towards recovery for your whole mental health.</pre>
                <a
                  href={`tel:(866) 209-6600`}
                  className={`btn btn-success btn-lg btn-call mt-3`}
                >
                  Call (866) 209-6600
                </a>
              </Col>
            </Row>
          </Container>
        </header>

        <section className={`py-5`}>
          <Container>
            <Row className={`justify-content-center text-center`}>
              <Col xs={10} md={8}>
                <Row>
                  <Col xs={12} md={6}>
                    <address>
                      8946 Madison Avenue <br/>
                      Fair Oaks, CA 95628-4010
                    </address>
                  </Col>
                  <Col xs={12} md={6}>
                    <address>
                      8950 Cal Center Drive <br/>
                      Suite 165B <br/>
                      Sacramento, CA 95826
                    </address>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <hr />

        <section className={`py-5 text-center`}>
          <InsuranceProviders />

          <Link to={`/insurance/`} className={`btn btn-primary mt-4`}>
            Learn How Insurance Covers You
          </Link>
        </section>

        {/* <section>
          <LayoutMap map={data.wp.globalContent.globalContact} />
        </section> */}

        <FreedomBanner />
      </>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 261 }) {
      title
      seo {
        metaTitle
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      content
    }
    wp {
      globalContent {
        globalButtons {
          buttonSettings {
            buttonText
            linkType
            internalLink {
              ... on WpPost {
                id
                uri
              }
              ... on WpPage {
                id
                uri
              }
            }
            externalLink
            htmlClass
          }
        }
        globalContact {
          phone
          address
          map {
            latitude
            longitude
            zoom
          }
        }
      }
    }
  }
`;

export default ContactUsPage;
